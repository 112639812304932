/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

import Layout from "~components/Layout";
import SEO from "~components/SEO";

import { AppContext } from "~context/AppContext";

class ProjectPageComponent extends Component {
  componentDidMount() {}

  //

  render() {
    const { appContext, fields, frontmatter, markdownRemark } = this.props;

    return (
      <>
        <SEO
          frontmatterTitle={frontmatter.title}
          frontmatterDescription={frontmatter.synopsis}
          frontmatterKeywords={frontmatter.seoKeywords}
          pathname={fields.slug}
        />

        <Layout className="project-page relative z-10 text-bone">
          <div className="grid pt-4">
            <Link
              to="/"
              className="project-page__home-button f3 text-left flex items-center"
            >
              <span className="project-page__home-button__arrow">← </span>
              <span className="b1 ml-3 whitespace-no-wrap">Home</span>
            </Link>
          </div>

          <section className="project-page__intro w-full grid pt-8">
            <div className="grid-end--10 grid-end--xs-12 mb-8">
              <h1 className="f1 font-bold">{frontmatter.title}</h1>
            </div>

            {frontmatter.featuredImage &&
              frontmatter.featuredImage.childImageSharp &&
              appContext.device === `mobile` && (
                <div className="project-page__image relative grid-end--4 grid-end--xs-12 grid-start--9 grid-start--xs-1 pb-2">
                  <div className="project-page__image-container w-full sticky xs:relative pt-12 xs:pt-0">
                    <Img
                      fluid={frontmatter.featuredImage.childImageSharp.fluid}
                      objectFit="contain"
                      objectPosition="50% 50%"
                      imgStyle={{ maxHeight: 600, width: `100%` }}
                    />
                  </div>
                </div>
              )}

            <div className="grid-end--7 grid-end--xs-12 mb-16">
              <h3 className="grid-end--7 grid-end--xs-12 f3 font-medium mb-8">
                {frontmatter.synopsis}
              </h3>

              <div
                className="b1"
                dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
              ></div>
            </div>

            {frontmatter.featuredImage &&
              frontmatter.featuredImage.childImageSharp &&
              appContext.device !== `mobile` && (
                <div className="project-page__image relative grid-end--4 grid-end--xs-12 grid-start--9 grid-start--xs-1">
                  <div className="project-page__image w-full xs:relative sticky pt-8 xs:pt-0">
                    <Img
                      fluid={frontmatter.featuredImage.childImageSharp.fluid}
                      objectFit="contain"
                      imgStyle={{
                        maxHeight: 600,
                        width: `100%`
                      }}
                    />
                  </div>
                </div>
              )}
          </section>
        </Layout>
      </>
    );
  }
}

const ProjectPage = ({ data }) => {
  const appContext = useContext(AppContext);

  const { markdownRemark } = data;
  const { siteMetadata: metadata } = data.site;
  const { fields, frontmatter } = markdownRemark;

  return (
    <ProjectPageComponent
      appContext={appContext}
      fields={fields}
      frontmatter={frontmatter}
      markdownRemark={markdownRemark}
      metadata={metadata}
    />
  );
};

export default ProjectPage;

export const ProjectPageQuery = graphql`
  query ProjectPage($id: String!) {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            seoDescription
            seoKeywords
            client
            synopsis
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        seoDescription
        seoKeywords
        title
        client
        synopsis
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
    site {
      siteMetadata {
        title
        description
        keywords
        author
        url
        twitterUsername
      }
    }
  }
`;
